import { toFormValidator } from '@vee-validate/zod'
import * as zod from 'zod'

export const validationSchema = toFormValidator(
    zod.object({
        firstName: zod.string().min(3, { message: 'Required' }),
        lastName: zod.string().min(3, { message: 'Required' }),
        email: zod.string().email('Must be a valid email'),
        phoneNumber: zod.string().refine(value => /^\d{10}$/.test(value), {
            message: 'Invalid mobile number. Please enter a 10-digit number without spaces or special characters.'
        })
    })
)
